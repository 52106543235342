
import React, { useEffect, useState } from "react";
import { WhoAmISvc } from "../auth/WhoAmISvc";
import { FetchInitialHypothesisResponse } from "../commonSrc/constant/type";
import DI_TYPES from "../diTypes";
import diContainer from '../inversify.config'
import ShowOneConfirmingLabRequestComponent from "../lab/ShowOneConfirmingLabRequestComponent";
import { getLogger } from '../log/getLogger'
import safeStringify from "../string/safeStringify";
import { InitialHypothesisFetcher } from "./InitialHypothesisFetcher";
import ShowOneHypothesisComponent from "./ShowOneHypothesisComponent";
import { useDispatch, useSelector } from "react-redux";
import { SbhcCompositeState } from "../state/SbhcCompositeState";
import { useDeveloperMode } from "../developer/useDeveloperMode";
import { AsyncThunkAction } from "@reduxjs/toolkit";
import { clearMyFeedbackUpstreamDangerWillRobinsonThunk, clearMyQuestionnaireUpstreamDangerWillRobinsonThunk, IThunkApi, setClearMyFeedbackDangerWillRobinson, setClearMyQuestionnaireDangerWillRobinson, setQuestionnaireSubmittedFlag, setUnsubmitDangerWillRobinson, unsubmitUpstreamDangerWillRobinsonThunk } from "../questionnaire/questionnaireSlice";
import { useNavigate } from "react-router";
import { useQuestionnaireSubmitted } from "../questionnaire/useQuestionnaireSubmitted";
import { HypothesisCodeEnum } from "../commonSrc/hypothesis/HypothesisCodeEnum";
import { Link } from 'react-router-dom'

const LOG = getLogger('hypothesis.InitialHypothesisComponent')
let  numFetchInitialHypothesis: number = 0


export default function InitialHypothesisComponent () {
  const navigate = useNavigate();
  const [isQuestionnaireSubmitted] = useQuestionnaireSubmitted()

  useEffect(() => {
    if (isQuestionnaireSubmitted === false) {
      navigate(`/questionnaire`, { replace: true });
    }
  }, []);

  const [syncDiagnosticAnswersToBackEndThunkStatus, setSyncDiagnosticAnswersToBackEndStatus] = useState('idle')
  const dispatch = useDispatch()


  const whoAmISvc: WhoAmISvc = diContainer.get<WhoAmISvc>(DI_TYPES.WhoAmISvc)
  const loggedInFlag: boolean = whoAmISvc.amILoggedIn()
  const oauth2Username: string | undefined = whoAmISvc.getMyUsername()
  const oauth2UserId: string | undefined = whoAmISvc.getMyUserId()
  const initialHypothesisFetcher: InitialHypothesisFetcher = diContainer.get<InitialHypothesisFetcher>(DI_TYPES.InitialHypothesisFetcher)
  const [cachedResponseFromServer, setCachedResponseFromServer] = useState<FetchInitialHypothesisResponse | undefined>(undefined);

  LOG.debug(`InitialHypothesisComponent() - oauth2UserId = ${oauth2UserId}`)

  // https://stackoverflow.com/questions/53945763/componentdidmount-equivalent-on-a-react-function-hooks-component
  useEffect(() => {
    // let cachedResponseFromServer: FetchInitialHypothesisResponse;
    numFetchInitialHypothesis++;
    initialHypothesisFetcher.fetchInitialHypothesis(oauth2UserId as string).then((response: FetchInitialHypothesisResponse) => {
      LOG.debug(`InitialHypothesisComponent() - numFetchInitialHypothesis = ${numFetchInitialHypothesis}`)
      LOG.debug(`InitialHypothesisComponent() - initialHypothesisFetcher.fetchInitialHypothesis(): Returned a response = ${safeStringify(response)}`)
      setCachedResponseFromServer(response)
    })
  }, []);
  

  const [developerMode, updateDeveloperMode] = useDeveloperMode();


  const alwaysTrue = true
  let anyRedFlagsAnywhere = false;
  let numDefinitePossibilities: number = 0
  if (cachedResponseFromServer != null && cachedResponseFromServer.data != null && cachedResponseFromServer.data.hypotheses != null ) {
    for( let ii = 0; ii < cachedResponseFromServer.data.hypotheses.length; ii++ ) {
      let hypothesisUnderTest = cachedResponseFromServer.data.hypotheses[ii]
      if (hypothesisUnderTest != null) {
        if (hypothesisUnderTest.redFlagsPresent === true) {
          anyRedFlagsAnywhere = true;
        }
        if (hypothesisUnderTest.definitePossibility === true) {
          numDefinitePossibilities++;
        }
      }
    }
  }

  const realtimeQuestionnaireSubmittedFlag: boolean | undefined = useSelector((state: SbhcCompositeState) => {
    return state?.questionnaire?.questionnaireSubmittedFlag ?? undefined
  })

  const isThereOneOrMoreLabRequests: boolean = useSelector((state: SbhcCompositeState) => {
    return cachedResponseFromServer != null 
    && cachedResponseFromServer.data != null 
    && cachedResponseFromServer.data.aggregatedConfirmingLabRequests
    && cachedResponseFromServer.data.aggregatedConfirmingLabRequests.length > 0 
  })

  const shouldRedFlagsBlockDisplayOfResults: boolean | undefined = useSelector((state: SbhcCompositeState) => {
    if (anyRedFlagsAnywhere && developerMode != true) {
      return true
    }
    return false
  })

  const willTakeHelpfulToneAndBeDisplayingZeroOrMoreResults: boolean | undefined = useSelector((state: SbhcCompositeState) => {
    // if (developerMode == true) {
    //   return true
    // }
    
    if (numDefinitePossibilities >= 0 && !anyRedFlagsAnywhere) {
      return true
    }
    return false
  })  

  const willTakeHelpfulToneAndBeDisplayingOneOrMoreResults: boolean | undefined = useSelector((state: SbhcCompositeState) => {
    // if (developerMode == true) {
    //   return true
    // }
    
    if (numDefinitePossibilities >= 1 && !anyRedFlagsAnywhere) {
      return true
    }
    return false
  })

  const onClearMyQuestionnaireDangerWillRobinsonUpstream = () => {
    LOG.warn('onClearMyQuestionnaireDangerWillRobinsonUpstream(): Entering')

    try {
      setSyncDiagnosticAnswersToBackEndStatus('pending')

      LOG.debug(`onClearMyQuestionnaireDangerWillRobinsonUpstream(): Preparing to dispatch a clearMyQuestionnaireUpstreamDangerWillRobinsonThunk()...`)
      const originalPromiseResult: AsyncThunkAction<any, void, IThunkApi> = dispatch(clearMyQuestionnaireUpstreamDangerWillRobinsonThunk())
      LOG.debug(`onClearMyQuestionnaireDangerWillRobinsonUpstream(): Finished dispatching a clearMyQuestionnaireUpstreamDangerWillRobinsonThunk().  originalPromiseResult = ${safeStringify(originalPromiseResult)}`)
    } catch (err) {
      LOG.error('onClearMyQuestionnaireDangerWillRobinsonUpstream(): err = ', err)
    } finally {
      setSyncDiagnosticAnswersToBackEndStatus('idle')
    }
  };

  const onClearMyFeedbackDangerWillRobinsonUpstream = () => {
    LOG.warn('onClearMyFeedbackDangerWillRobinsonUpstream(): Entering')

    try {
      setSyncDiagnosticAnswersToBackEndStatus('pending')

      LOG.debug(`onClearMyFeedbackDangerWillRobinsonUpstream(): Preparing to dispatch a clearMyFeedbackUpstreamDangerWillRobinsonThunk()...`)
      const originalPromiseResult: AsyncThunkAction<any, void, IThunkApi> = dispatch(clearMyFeedbackUpstreamDangerWillRobinsonThunk())
      LOG.debug(`onClearMyFeedbackDangerWillRobinsonUpstream(): Finished dispatching a clearMyQuestionnaireUpstreamDangerWillRobinsonThunk().  originalPromiseResult = ${safeStringify(originalPromiseResult)}`)
    } catch (err) {
      LOG.error('onClearMyFeedbackDangerWillRobinsonUpstream(): err = ', err)
    } finally {
      setSyncDiagnosticAnswersToBackEndStatus('idle')
    }
  };

  const onUnsubmitDangerWillRobinsonUpstream = () => {
    LOG.warn('onUnsubmitDangerWillRobinsonUpstream(): Entering')

    try {
      setSyncDiagnosticAnswersToBackEndStatus('pending')

      LOG.debug(`onUnsubmitDangerWillRobinsonUpstream(): Preparing to dispatch a syncDiagnosticAnswersToBackEndThunk()...`)
      const originalPromiseResult: AsyncThunkAction<any, void, IThunkApi> = dispatch(unsubmitUpstreamDangerWillRobinsonThunk())
      LOG.debug(`onUnsubmitDangerWillRobinsonUpstream(): Finished dispatching a unsubmitUpstreamDangerWillRobinsonThunk().  originalPromiseResult = ${safeStringify(originalPromiseResult)}`)
    } catch (err) {
      LOG.error('onUnsubmitDangerWillRobinsonUpstream(): err = ', err)
    } finally {
      setSyncDiagnosticAnswersToBackEndStatus('idle')
    }
  };

  const onUnsubmitDangerWillRobinson = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): Promise<void> => {
    LOG.debug(`onUnsubmitDangerWillRobinson() - Entering with event = ${safeStringify(event)}`)
    dispatch(setUnsubmitDangerWillRobinson())
    onUnsubmitDangerWillRobinsonUpstream()
    dispatch(setQuestionnaireSubmittedFlag(false))
    navigate(`/devmode`, { replace: true });
  } 

  const onClearMyQuestionnaireDangerWillRobinson = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): Promise<void> => {
    LOG.debug(`onClearMyQuestionnaireDangerWillRobinson() - Entering with event = ${safeStringify(event)}`)
    dispatch(setClearMyQuestionnaireDangerWillRobinson())
    onClearMyQuestionnaireDangerWillRobinsonUpstream()
    dispatch(setQuestionnaireSubmittedFlag(false))
    navigate(`/devmode`, { replace: true });
  } 

  const onClearMyFeedbackDangerWillRobinson = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): Promise<void> => {
    LOG.debug(`onClearMyFeedbackDangerWillRobinson() - Entering with event = ${safeStringify(event)}`)
    dispatch(setClearMyFeedbackDangerWillRobinson())
    onClearMyFeedbackDangerWillRobinsonUpstream()
    dispatch(setQuestionnaireSubmittedFlag(false))
    navigate(`/devmode`, { replace: true });
  } 

  const onUnsubmitAndClearHypothesesDangerWillRobinson = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): Promise<void> => {
    LOG.debug(`onUnsubmitAndClearHypothesesDangerWillRobinson() - Entering with event = ${safeStringify(event)}`)
    dispatch(setUnsubmitDangerWillRobinson())
    onUnsubmitDangerWillRobinsonUpstream()
    dispatch(setClearMyFeedbackDangerWillRobinson())
    onClearMyFeedbackDangerWillRobinsonUpstream()
    dispatch(setQuestionnaireSubmittedFlag(false))
    navigate(`/devmode`, { replace: true });
  }

  const onToggleDeveloperMode = async (event: React.ChangeEvent<HTMLInputElement> | any): Promise<void> => {
    LOG.debug(`onToggleDeveloperMode() - Entering with event.target.value = ${event.target.value} (${typeof event.target.value}),   event.target.checked = ${event.target.checked} (${typeof event.target.checked}),  event = ${safeStringify(event)}`, event)
  
    if (developerMode) {
      updateDeveloperMode(false)
    } else {
      updateDeveloperMode(true)
    }
  }

  return (
    <div>
      <header className="page-header">
        <div className="inner">
          <img src="results.svg" alt="" />
          <div className="text-block">
            <h1>Well done! You have completed your questionnaire.</h1>
            <p>
              Congratulations on completing your first step! Please pick a time via <a href="https://calendly.com/kathleen-iozc" target="_blank">Calendly</a> for our care coordinator to call you to schedule an appointment with the doctor for the lab tests needed to confirm your diagnosis. This scheduling call will take less than 10 minutes.
            </p>
          </div>
        </div>
      </header>

      <div className="initialHyptothesisFeedback-content">
        {developerMode &&
          <div className="mt-2">
            <div className="card">
              <div className="card-header developer-mode">
                Danger Will Robinson
              </div>
              <div className="card-body">
                <i>Reload your browser after clicking any of these buttons</i><br/><br/>
                <span>(Sometimes you have to Click button, reload-browser, click same button again)</span><br/><br/>

                {/* <button className="btn btn-developer-mode btn-sm" onClick={(event) => { event.preventDefault(); onClearMyFeedbackDangerWillRobinson(event);  return false;}}>Clear Hypothesis Feedback</button>
                &nbsp;
                <button className="btn btn-developer-mode btn-sm" onClick={(event) => { event.preventDefault(); onUnsubmitDangerWillRobinson(event);  return false;}}>Unsubmit</button>
                &nbsp; */}
                <button className="btn btn-developer-mode btn-sm" onClick={(event) => { event.preventDefault(); onUnsubmitAndClearHypothesesDangerWillRobinson(event);  return false;}}>Unsubmit &amp; Clear Hypotheses</button>
                &nbsp;
                <button className="btn btn-developer-mode btn-sm" onClick={(event) => { event.preventDefault(); onClearMyQuestionnaireDangerWillRobinson(event);  return false;}}>Clear Questionnaire Answers</button>
                <div className="form-check form-switch">
                  <input className="form-check-input" type="checkbox" id="devModeToggleSwitch"  checked={developerMode} onChange={async (event) => await onToggleDeveloperMode(event)}/>
                  <label className="form-check-label" htmlFor="devModeToggleSwitch">Developer Mode</label>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
      
      {/* <div>
        {realtimeQuestionnaireSubmittedFlag === true && <>Your questionnaire <b>has been submitted</b></>}
        {realtimeQuestionnaireSubmittedFlag === false && <>Your questionnaire is in-progress</>}
        {realtimeQuestionnaireSubmittedFlag == null && <>The submission status of your questionnaire is unknown</>}
      </div> */}

      {/* <!-- wait this again? --> */}
      <div className="initialHyptothesisFeedback-content">

        {anyRedFlagsAnywhere === true && 
          <>
            <div className="developer-mode">
              anyRedFlagsAnywhere == true
            </div>
            <div className="alert alert-danger" role="alert">Thank you for completing the questionnaire. Unfortunately, your symptoms do not qualify for inclusion in this clinical study, as one or more of your symptoms should be evaluated with your doctor in person. <p>We encourage to make an appointment with your doctor to discuss the symptoms you are experiencing.</p></div>
          </> 
        }
        
        { developerMode && <div className="developer-mode">
          <pre>
            {JSON.stringify(cachedResponseFromServer)}
            size = {JSON.stringify(cachedResponseFromServer)?.length || -1}
          </pre>       
        </div> }

        { willTakeHelpfulToneAndBeDisplayingOneOrMoreResults && 

            <div className="results">
              { developerMode && <div className="developer-mode">
                willTakeHelpfulToneAndBeDisplayingOneOrMoreResults == true
              </div> }
              <div className="result-block">
                
                {/* <div className="card">
                  <div className="card-body">
                    While not conclusive yet, with your combination of symptoms, we think we might be able to help.  See our initial findings below and then <Link to='/insurance'>JOIN THE STUDY BUTTON</Link>
                  </div>
                </div> */}

                {/* enh
                <pre>
                  {JSON.stringify(cachedResponseFromServer)}
                  size = {JSON.stringify(cachedResponseFromServer)?.length || -1}
                </pre> */}

                <h2>Based on your symptoms, you appear to be struggling with one or more of the following:</h2>
                <div>
                  <div className="accordion accordion-flush" id="accordionHypothesis">
                    {(cachedResponseFromServer != null && cachedResponseFromServer.data != null && cachedResponseFromServer.data.hypotheses != null) ? cachedResponseFromServer.data.hypotheses.map((hypothesis: any, index: number)=>{
                      return <ShowOneHypothesisComponent hypothesis={hypothesis} parentId="accordionHypothesis" key={index}/>
                    }) : ''}
                  </div>
                </div>
                <p>
                  <h2>
                    We'll call you within two business days to schedule your 15-minute telehealth appointment with a doctor to prescribe the lab work you will need to confirm your diagnosis. 
                  </h2>
                </p>
              </div>

              {/* <div className="result-block">
                {isThereOneOrMoreLabRequests &&
                  <>
                    <h2>Here’s the lab work your doctor should order to confirm your diagnosis.</h2>
                    <div>
                      <div className="accordion accordion-flush" id="accordionConfirmingLabRequests">
                        {(cachedResponseFromServer != null && cachedResponseFromServer.data != null && cachedResponseFromServer.data.aggregatedConfirmingLabRequests != null) ? cachedResponseFromServer.data.aggregatedConfirmingLabRequests.map((confirmingLabRequest: any,index: number)=>{
                          return <ShowOneConfirmingLabRequestComponent confirmingLabRequest={confirmingLabRequest} parentId="accordionConfirmingLabRequests"/>
                        }) : ''}
                      </div>
                    </div>
                  </>
                }     
              </div> */}
            </div>
        }
        {numDefinitePossibilities === 0 && 
          <>
            { developerMode && <div className="developer-mode">
              numDefinitePossibilities == 0
            </div> }
            <div>
              Thank you for filling out your questionnaire. Based on your symptoms, it doesn't look like you're struggling with any of the disorders that we currently support. It's best to make an appointment with your doctor for a full check-up about the symptoms you're experiencing.
            </div>
          </>
        }
        {developerMode && cachedResponseFromServer != null && 
          <div className="developer-mode mt-4">
            <h1>Developer Mode Says</h1>
            <pre>{JSON.stringify(cachedResponseFromServer, null, 2)}</pre>
          </div>
        }
      </div>
    </div>
  )
}